import { useCallback, useEffect, useState } from 'preact/hooks';
import { BroadcastingLocalStorageProxy } from './BroadcastingLocalStorageProxy';
import { h } from 'preact';

/**
 * This component renders the pro features config modal template to allow users to enable/disable pro features
 * @param {object} props
 * @param {{saveKey: string; title: string; description: string}[]} props.features
 * @param {() => void} props.onClose
 * @returns
 */
export const ProFeaturesConfig = ({ onClose, features }) => {
	/** @type {{ [key: string]: boolean }} */
	const initState = features.reduce(
		(acc, { saveKey }) =>
			Object.assign(acc, {
				[saveKey]: Boolean(BroadcastingLocalStorageProxy.getItem(saveKey))
			}),
		{}
	);

	const [featuresStateMap, setFeaturesStateMap] = useState(initState);

	useEffect(() => {
		const handleStorageChange = ({ key, newValue }) => {
			if (key && key in featuresStateMap) {
				setFeaturesStateMap((prev) => ({ ...prev, [key]: Boolean(newValue) }));
			}
		};

		// listen to storage changes in other components like related articles to update the disable feature state
		window.addEventListener(
			BroadcastingLocalStorageProxy.getEventName(),
			handleStorageChange,
			false
		);

		return () => {
			window.removeEventListener(
				BroadcastingLocalStorageProxy.getEventName(),
				handleStorageChange
			);
		};
	}, []);

	const handleClose = useCallback(
		(/** @type {{ type: string; key: string; }} */ e) => {
			if (e.type === 'keydown' && e.key !== 'Escape') return;
			setFeaturesStateMap(initState);
			onClose();
		},
		[initState]
	);

	const saveProFeaturesConfigState = useCallback(() => {
		Object.entries(featuresStateMap).forEach(([key, value]) => {
			value
				? BroadcastingLocalStorageProxy.setItem(key, 'true')
				: BroadcastingLocalStorageProxy.removeItem(key);
		});

		onClose();
	}, [featuresStateMap, onClose]);

	const changedToEnabledFeatures = Object.entries(featuresStateMap)
		.filter(([key, value]) => initState[key] && !value)
		.map(([key]) => features.find((feat) => feat.saveKey === key).featureCode);

	const changedToDisabledFeatures = Object.entries(featuresStateMap)
		.filter(([key, value]) => !initState[key] && value)
		.map(([key]) => features.find((feat) => feat.saveKey === key).featureCode);

	return (
		<div className="pro-features-config-template" onKeyDown={handleClose}>
			<button
				className="pro-features-config-icon o-overlay__close"
				onClick={handleClose}
			/>
			<div className="pro-features-config">
				<div className="pro-features-config-wrapper">
					<div className="pro-features-config-title">
						<span className="pro-features-config-title__main">
							Customise article page
						</span>
						<span className="pro-features-config-title__prompt">
							Only available to people with an FT Professional subscription
						</span>
					</div>
				</div>
				<div className="pro-features-config--toggle">
					{features.map(({ saveKey, title, description }) => (
						<div className="pro-features-config--toggle__wrapper" key={saveKey}>
							<div className="o-forms-input__label pro-features-config--toggle-label">
								<span className="pro-features-config--toggle-label__main">
									{title}
								</span>
								<span className="pro-features-config--toggle-label__prompt">
									{description}
								</span>
							</div>
							<span className="o-forms-input o-forms-input--inline o-forms-input--toggle pro-features-config--toggle__wrapper-input">
								<label htmlFor={saveKey} className="">
									<input
										id={saveKey}
										type="checkbox"
										name={saveKey}
										value={saveKey}
										checked={!featuresStateMap[saveKey]}
										onChange={() =>
											setFeaturesStateMap({
												...featuresStateMap,
												[saveKey]: !featuresStateMap[saveKey]
											})
										}
									/>
									<span className="o-forms-input__label">
										<span className="o-forms-input__label__main"></span>
									</span>
								</label>
							</span>
						</div>
					))}
				</div>
			</div>

			<button
				type="button"
				className="o-buttons o-buttons--big o-buttons--secondary o-buttons--mono"
				data-trackable="update-pro-features-settings"
				data-trackable-context-custom={JSON.stringify([
					{ name: 'changed-to-enabled', value: changedToEnabledFeatures },
					{ name: 'changed-to-disabled', value: changedToDisabledFeatures }
				])}
				onClick={saveProFeaturesConfigState}
			>
				Done
			</button>
		</div>
	);
};
