const oViewport = require('@financial-times/o-viewport').default;

const checkCookieConsent = () => {
	const FTCONSENT_COOKIE_PATTERN = /FTConsent=([^;]+)/;
	const match = document?.cookie?.match(FTCONSENT_COOKIE_PATTERN);

	if (!match) return false;

	const consentCookie = decodeURIComponent(match[1]);
	return (
		consentCookie.includes('behaviouraladsOnsite:on') &&
		consentCookie.includes('recommendedcontentOnsite:on')
	);
};

function isMobile() {
	const width = oViewport?.getSize()?.width ?? window?.innerWidth;
	return width ? width < 740 : false;
}

function isInTest(flags) {
	const FT_FLAGS_VARIANT_PATTERN = /^variant\d$/;
	return FT_FLAGS_VARIANT_PATTERN.test(flags?.get('articleJourneyTestsZ'));
}

export const canShowExplore = (flags) => {
	return checkCookieConsent() && isInTest(flags) && isMobile();
};
