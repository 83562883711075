/**
 * Clone the concepts element to the right hand rail
 *
 * If the cloned element was successfully placed then hide the original
 * at desktop breakpoints
 */
export function cloneConceptsToRhr() {
	try {
		/** @type {(HTMLDivElement & {cmd: ((rhr: {placeItem: ({}) => Element}) => void)[]}) | null} */
		const rhrEl = document.querySelector("[data-component='article-rhr']");
		const conceptsEl = document.querySelector('.concept-list');

		if (!rhrEl) throw new Error('RHR element not found');
		if (!conceptsEl) throw new Error('ConceptList element not found');

		const conceptsHeight = conceptsEl.getBoundingClientRect().height;
		const conceptsMargin = 100;
		rhrEl.cmd = rhrEl?.cmd || [];
		rhrEl.cmd.push((rhr) => {
			const clonedConceptsEl = rhr.placeItem({
				item: conceptsEl.cloneNode(true),
				itemSpace: conceptsHeight + conceptsMargin,
				placement: 'middle'
			});

			if (clonedConceptsEl) {
				conceptsEl.classList.add('cloned-to-rhr');
			}
		});
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn('Error cloning Concept List to RHR', error);
	}
}
