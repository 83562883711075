import audioTeaserView from './audio-teaser-view';
import trackCustomFlourishGraphics from './custom-flourish-graphics';
import graphicView from './graphic';

// This tracking is used for Article pages, for Live Blogs please see ../utils/initialise-ntracking.js
export default (oTracking) => {
	graphicView(oTracking);
	audioTeaserView(oTracking, '.o-teaser--audio');
	trackCustomFlourishGraphics();
};
