import { getArticleId } from '../utils/article';
export { canShowExplore } from './utils';

const isFTdotcom = (script) =>
	script.baseURI && script.baseURI.startsWith('https://www.ft.com/');
const isFTowned = (script) =>
	script.src && script.src.startsWith('https://www.ft.com/');

const FLAGS_TO_SEND = ['articleJourneyTestsZ', 'adsForcePG', 'adsPGArticle'];
const writeFlag = (flags) => (key) =>
	flags.get(key)
		? `${key}:${flags.get(key) === true ? 'on' : flags.get(key)}`
		: null;

export async function init(flags) {
	const placeholder = document.querySelector(
		'[data-component="onward-journey__bottom"]'
	);
	if (!placeholder) {
		// we just return because we could miss it sometimes
		return;
	}

	const contentId = getArticleId();
	if (!contentId) {
		throw new Error('Cannot get Content ID');
	}

	placeholder.innerHTML = await fetchOnward(contentId, flags);

	const [container] = [
		...placeholder.getElementsByClassName('onward-container')
	];
	if (!container) {
		throw new Error('Container not found');
	}

	// To avoid jumps, display the component only when the resources are loaded
	container.style.display = 'none';

	Promise.all([
		...loadOnwardStyles(placeholder),
		...loadOnwardScripts(placeholder)
	])
		.then(() => {
			window.onwardComponent.init(flags);
			container.style.display = 'block';

			// Hide original Partner Content (since it'll be replaced by Onward's Partner Content)
			[
				...document.getElementsByClassName('promoted-content__container')
			].forEach((node) => {
				node.style.display = 'none';
			});

			initComponentTracking('explore');
		})
		.catch((error) => {
			// Hide Onward + Partner Content
			placeholder.style.display = 'none';
			throw new Error(`Resources failed to load: ${error}`);
		});
}

function loadOnwardScripts(placeholder) {
	const toLoad = [...placeholder.getElementsByTagName('script')];
	if (!toLoad.length) {
		return [Promise.reject('Onward scripts not found')];
	}

	const alreadyLoaded = new Set(
		[...document.scripts]
			.filter((script) => isFTdotcom(script) && isFTowned(script))
			.map((script) => script.src)
	);

	const onLoadPromises = [];
	for (const script of toLoad) {
		const src = script.getAttribute('data-src');
		if (src && !alreadyLoaded.has(src) && src.indexOf('webpack-runtime') <= 0) {
			const newScript = document.createElement('script');
			newScript.type = 'text/javascript';
			newScript.async = true;
			newScript.defer = true;
			newScript.src = src;

			onLoadPromises.push(onLoadResource(newScript));
			document.head.appendChild(newScript);
		}
	}

	return onLoadPromises;
}

function loadOnwardStyles(placeholder) {
	const stylesheets = [...placeholder.getElementsByTagName('link')];
	if (!stylesheets.length) {
		return [Promise.reject('Onward styles not found')];
	}
	return stylesheets.map(onLoadResource);
}

function onLoadResource(element) {
	return new Promise((resolve) => {
		element.onload = () => {
			resolve(true);
		};
	});
}

async function fetchOnward(contentId, flags) {
	const url = new URL('/explore/content/filters?format=html', location.origin);
	const fetchOpts = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ contentId })
	};

	const flagsHeader = FLAGS_TO_SEND.map(writeFlag(flags))
		.filter(Boolean)
		.join(',');
	if (flagsHeader) {
		fetchOpts.headers['ft-flags'] = flagsHeader;
	}

	const response = await fetch(url.toString(), fetchOpts);
	if (!response.ok) {
		throw new Error("Onward couldn't be fetched");
	}
	return response.text();
}

// We want to track which recommendations are viewed (lure or this)
// In particular we want to know where clicks on recommended articles come from
export const initComponentTracking = (recommendations) => {
	const intersectionObserverOptions = {
		root: null,
		rootMargin: '0px',
		threshold: 0.5
	};

	let componentWasViewed = false;
	const handleIntersection = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting && !componentWasViewed) {
				componentWasViewed = true;
				window?.oTracking?.view?.init({
					selector: '.onward-journey',
					category: 'component',
					action: 'view'
				});
			}
		});
	};

	const observer = new IntersectionObserver(
		handleIntersection,
		intersectionObserverOptions
	);
	const onwardTarget = document.querySelector('.onward-journey');
	onwardTarget?.setAttribute(
		'data-trackable-context-custom',
		JSON.stringify([
			{
				name: 'onward-journey-variant',
				value: recommendations
			}
		])
	);
	if (onwardTarget) observer.observe(onwardTarget);
};
