export class BroadcastingLocalStorageProxy {
	static getEventName() {
		return 'proFeaturesConfig.storage';
	}

	/**
	 * @param {string} key
	 * @param {string} value
	 */
	static setItem(key, value) {
		localStorage.setItem(key, value);
		window.dispatchEvent(
			new StorageEvent(BroadcastingLocalStorageProxy.getEventName(), {
				key,
				newValue: value
			})
		);
	}

	/**
	 * @param {string} key
	 */
	static removeItem(key) {
		localStorage.removeItem(key);
		window.dispatchEvent(
			new StorageEvent(BroadcastingLocalStorageProxy.getEventName(), {
				key,
				newValue: null
			})
		);
	}

	/**
	 * @param {string} key
	 */
	static getItem(key) {
		return localStorage.getItem(key);
	}
}
