/**
 * Helper function to retrieve a cookie by its name
 * @param {string} cookieName - The name of the cookie to retrieve
 * @returns {string|null} - Returns the cookie value if found, otherwise null
 */
const getCookieByName = (cookieName) => {
	const cookieString = document.cookie;

	// Find and return the cookie value if it exists
	const cookie = cookieString
		.split('; ')
		.find((row) => row.startsWith(`${cookieName}=`));

	return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
};

/**
 * Helper function to check if the FTConsent cookie contains behaviouraladsOnsite as 'on'
 * When 'behaviouraladsOnsite:on' is present, it indicates that the user has
 * given consent not only for tracking their behaviour for advertising purposes,
 * but also for sending their event data to the Sub(x) Event Data API.
 * This has been confirmed by Compliance, ensuring that we are allowed to use
 * this consent for both ad tracking and feeding data into AI models for paywalls.
 * Slack conversations: https://financialtimes.slack.com/archives/C7SJG4SLR/p1722248738691729
 * https://financialtimes.slack.com/archives/C067V11G8/p1714467742592779
 * @returns {boolean} - Returns true if 'behaviouraladsOnsite' is 'on', otherwise false
 */
export const hasUserConsentedToBehaviouralTracking = () => {
	const ftConsentValue = getCookieByName('FTConsent');

	if (ftConsentValue) {
		// Check if behaviouraladsOnsite is 'on'
		const consentSettings = ftConsentValue.split(',');
		const behaviouralAdsOnsiteSetting = consentSettings.find(
			(setting) => setting === 'behaviouraladsOnsite:on'
		);

		return Boolean(behaviouralAdsOnsiteSetting);
	}

	// Return false if the FTConsent cookie does not exist or if the setting is not 'on'
	return false;
};
