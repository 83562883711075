import { render, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import * as nTracking from '@financial-times/n-tracking';

const WorkspaceTeaser = () => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		const handleTopicFollowed = () => {
			nTracking.broadcast('oTracking.event', {
				action: 'view',
				category: 'component',
				component: {
					type: 'teaser',
					name: 'workspace-teaser'
				}
			});
			setShow(true);
		};

		document.addEventListener(
			'myft.user.followed.concept.add',
			handleTopicFollowed
		);
		return () => {
			document.removeEventListener(
				'myft.user.followed.concept.add',
				handleTopicFollowed
			);
		};
	}, []);

	return (
		show && (
			<div className="workspace-teaser" data-trackable="workspace-teaser">
				<div className="workspace-teaser__description">
					<p className="workspace-teaser__description-title">
						Discover recommendations based on your interests, industry, role and
						recent reads in Workspace.
					</p>
					<button
						className="workspace-teaser__description-close"
						onClick={() => setShow(false)}
						data-trackable="workspace-teaser-close"
					/>
				</div>
				<a
					href="https://workspace.ft.com/"
					target="_blank"
					className="workspace-teaser__cta o-buttons o-buttons--primary o-buttons--big o-buttons--professional"
					data-trackable="workspace-teaser-cta"
					rel="noreferrer"
				>
					Go to Workspace
					<span className="workspace-teaser__cta-arrow-icon" />
				</a>
			</div>
		)
	);
};

export const initWorkspaceTeaser = () => {
	const parent = document.querySelector('.concept-list');

	if (!parent) return;

	const workspaceTeaser = document.createElement('div');
	parent.after(workspaceTeaser);
	render(<WorkspaceTeaser />, parent, workspaceTeaser);
};
