/**
 *
 * @param {{get: (key: string) => boolean}} flags
 * @returns {("marketData")[]}
 */
export const getActiveFeatures = (flags) => {
	/** @type {("marketData")[]}*/
	const features = [];

	if (flags.get('proStocksWidgetArticlePage')) {
		features.push('marketData');
	}

	return features;
};
