export default function graphicView(oTracking) {
	oTracking.view.init({
		selector: '[data-image-type="graphic"]',
		category: 'component',
		action: 'view',
		context: {
			component: 'graphic'
		},
		getContextData: undefined
	});
}
