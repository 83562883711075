import { render, h } from 'preact';
import { ProFeaturesConfig } from './ProFeaturesConfig';

/**
 *	This function renders the pro features config modal template to allow users to enable/disable pro features
 *
 * @param {object} params
 * @param {{saveKey: string; title: string; description: string}[]} params.features
 * @param {string} params.templateClassName
 * @param {() => void} params.onClose
 *
 * @returns {void}
 */
export const renderProFeaturesConfig = ({
	features,
	templateClassName,
	onClose
}) => {
	const root = document.querySelector(
		`.${templateClassName} .o-overlay__content`
	);
	if (!root) return;

	render(<ProFeaturesConfig onClose={onClose} features={features} />, root);
};

export const renderProFeaturesConfigButtons = (
	/** @type {string} */ parentsSelector
) => {
	const PRO_FEATURES_BUTTON_SELECTOR = 'js-pro-features-config';
	const createElement = (
		/** @type {('vertical' | 'horizontal')} */ position
	) => {
		const element = document.createElement('li');
		element.setAttribute('class', 'o-share__action share-nav__share-action');
		element.setAttribute('data-enterprise-sharing-tooltip', `${position}`);
		element.setAttribute('id', `share-button-${position}`);
		element.innerHTML = `
		<button
		type="button"
		class="o-share__icon o-share__icon--share ${PRO_FEATURES_BUTTON_SELECTOR}"
	  >
		<div class="o-share__icon__image">
		  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
			<path
			  d="M10 12.999H12.1711C12.5832 11.8343 13.6941 11 15 11C16.3059 11 17.4168 11.8343 17.8289 12.999H30V14.999H17.8296C17.4181 16.1647 16.3066 17 15 17C13.6934 17 12.5819 16.1647 12.1704 14.999H10V12.999ZM15 15C14.9851 15 14.9702 14.9997 14.9554 14.999C14.4238 14.9757 14 14.5373 14 14C14 13.4477 14.4477 13 15 13C15.5523 13 16 13.4477 16 14C16 14.5373 15.5762 14.9757 15.0446 14.999C15.0298 14.9997 15.0149 15 15 15Z"
			/>
			<path
			  d="M30 21.001H27.8289C27.4168 22.1657 26.3059 23 25 23C23.6941 23 22.5832 22.1657 22.1711 21.001L10 21.001L10 19.001L22.1704 19.001C22.5819 17.8353 23.6934 17 25 17C26.3066 17 27.4181 17.8353 27.8296 19.001H30V21.001ZM25 19C25.0149 19 25.0298 19.0003 25.0446 19.001C25.5762 19.0243 26 19.4627 26 20C26 20.5523 25.5523 21 25 21C24.4477 21 24 20.5523 24 20C24 19.4627 24.4238 19.0243 24.9554 19.001C24.9702 19.0003 24.9851 19 25 19Z"
			/>
			<path
			  d="M10 24.999H12.1711C12.5832 23.8343 13.6941 23 15 23C16.3059 23 17.4168 23.8343 17.8289 24.999H30V26.999H17.8296C17.4181 28.1647 16.3066 29 15 29C13.6934 29 12.5819 28.1647 12.1704 26.999H10V24.999ZM15 27C14.9851 27 14.9702 26.9997 14.9554 26.999C14.4238 26.9757 14 26.5373 14 26C14 25.4477 14.4477 25 15 25C15.5523 25 16 25.4477 16 26C16 26.5373 15.5762 26.9757 15.0446 26.999C15.0298 26.9997 15.0149 27 15 27Z"
			/>
		  </svg>
		</div>
		<span class="o-share__text"> Pro Features Configuration </span>
	  </button>
	  `;

		return element;
	};

	return Array.from(document.querySelectorAll(parentsSelector)).map(
		(parent) => {
			if (!parent || !parent.parentElement) return;

			const el = createElement(
				parent.parentElement?.className.includes('vertical')
					? 'vertical'
					: 'horizontal'
			);
			parent.appendChild(el);
			return el.querySelector(`.${PRO_FEATURES_BUTTON_SELECTOR}`);
		}
	);
};
