const getArticleElementData = () => {
	const articleEl = document.querySelector('article[role="main"]');

	return articleEl ? articleEl.dataset : {};
};

const getArticleId = () => getArticleElementData().contentId;

const getArticleUrl = () => {
	return (
		document.querySelector("link[rel='canonical']") &&
		document.querySelector("link[rel='canonical']").href
	);
};

const isFreeArticle = () => getArticleElementData().accessLevel === 'free';

const getElementText = (selector) => {
	const el = document.querySelector(selector);

	return el ? el.innerText : '';
};

const getTitle = () => document.title;

const getStandfirst = () => getElementText('.o-topper__standfirst');

const getPrimaryTheme = () => {
	const el = document.querySelector('a.js-primary-theme');
	if (!el) return;
	const data = el.dataset || {};
	data.prefLabel = el.innerText;

	return data;
};

const classNameExists = (className) =>
	!!document.getElementsByClassName(className).length;

export {
	getArticleId,
	getArticleUrl,
	isFreeArticle,
	getTitle,
	getStandfirst,
	getPrimaryTheme,
	classNameExists
};
