import myFtClient from 'next-myft-client';
import session from 'next-session-client';
import getToken from '@financial-times/n-myft-ui/myft/ui/lib/get-csrf-token';

export async function addTopicsToLoggedUser(topicIds = []) {
	if (!topicIds.length) return;

	const { uuid } = await session.uuid();
	const crsfToken = getToken();

	const myFtRequests = topicIds.map((topicId) =>
		myFtClient.add('user', uuid, 'followed', 'concept', topicId, {
			token: crsfToken
		})
	);

	Promise.all(myFtRequests);
}

export async function signupNewslettersToLoggedUser(newsletterIds = []) {
	if (!newsletterIds.length) return;

	const { uuid } = await session.uuid();
	const crsfToken = getToken();

	const newsletterRequests = newsletterIds.map((newsletterId) => {
		const url = `/__myft/api/alerts/${uuid}/newsletters/${newsletterId}/subscribe?redirect=/newsletters/`;

		return fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ token: crsfToken })
		}).catch((err) =>
			import('@financial-times/o-errors').then((oErrors) =>
				oErrors.default.report(err)
			)
		);
	});

	Promise.all(newsletterRequests);
}
