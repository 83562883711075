/**
 * On the client flag values are accessed via `flags.get(keyName)`
 *
 * @param {{get: (key: string) => boolean | string | undefined}} flags
 */
export function adsUsePGClient(flags) {
	return adsUsePG({
		adsForcePG: flags.get('adsForcePG'),
		adsPGArticle: flags.get('adsPGArticle'),
		'ads-pg-article': flags.get('ads-pg-article')
	});
}

/**
 * On the server the flags are a plain object, so we can check the values directly
 * Determine whether to render ads in legacy oAds format or as <pg-slot> elements
 *
 * @param {Record<string, boolean | string | undefined>} flags
 */
export function adsUsePG(flags) {
	return Boolean(
		flags.adsForcePG || flags.adsPGArticle || flags['ads-pg-article']
	);
}

/**
 * Checks if article content is a partner content or not using the isPartnerContent property
 * from the cp-content-pipeline response
 * @param {{ indicators : { isPartnerContent: boolean | null }}} content
 */
export function isPartnerContent(content) {
	return Boolean(content?.indicators?.isPartnerContent === true);
}
