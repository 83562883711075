/**
 * This helper centralises google extended access validation, it is consumed both server and client side.
 * To enable google extended access, relevant feature flags must be active and the incoming URL must include the following 4 query parameters:
 * 		gaa_at & gaa_ts && gaa_n && gaa_sig
 *
 * @param {string} searchParams the url query params
 * @param {Object} flags FT system flags
 * @returns {boolean} true if the user is eligible for an extended meter entitlement, otherwise false
 */
export function isUserExtendedAccessEligible(searchParams = '', flags = {}) {
	const urlParams = new URLSearchParams(searchParams);
	const userCommingFromGoogleShowcase = [
		'gaa_at',
		'gaa_ts',
		'gaa_n',
		'gaa_sig'
	].every((key) => urlParams.get(key));

	return flags.enableGoogleExtendedMeter && userCommingFromGoogleShowcase;
}
